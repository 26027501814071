export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ADMIN = 'ROLE_ADMIN'
export const USER = 'ROLE_USER'
export const SUPERVISOR = 'ROLE_SUPERVISOR'
export const COORDINATOR = 'ROLE_COORDINATOR'
export const COMMERCIAL = 'ROLE_COMMERCIAL'
export const ADMIN_ = 'admin'
export const COORDINATOR_ = 'coordinator'
export const SUPERVISOR_ = 'supervisor'
export const COMMERCIAL_ = 'commercial'
export const SUPER_ADMIN_ = 'super-admin'