<template>
 <router-view></router-view>

  <notifications :duration="5000" width="400" pause-on-hover/>
</template>

<script>

import {Notifications} from "@kyvg/vue3-notification";

export default {
  name: 'App',
  components: {
    Notifications
  }
}
</script>
